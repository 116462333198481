import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { TFunction } from 'i18next';
import { isEdge } from 'react-device-detect';
import * as yup from 'yup';

import { Button } from '@components/Button';
import PasswordInput from '@components/Formik/PasswordInput';
import { isValidIndiaPanNumberSchema } from '@components/Formik/validation/fieldDefinitions';
import {
  isRequiredConfirmPAN,
  schemaField,
} from '@components/Formik/validation/schemaDefinitions';
import Icon from '@components/Icon';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import Title from '@components/Title';

import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import { useAddPan } from '@repositories/government-id';
import { useGetGin } from '@repositories/government-id/hooks';
import { useFetchProfileDetails } from '@repositories/profile/hooks';

import { constructFullName } from '@utils/constructFullName';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

export interface FormValues {
  panNumber: string;
  confirmPanNumber: string;
}

const IndiaPanForm: React.FC = () => {
  const { user } = useAppConfig();
  const validationSchema = (t: TFunction) =>
    yup.object().shape({
      panNumber: isValidIndiaPanNumberSchema(t),
      confirmPanNumber: schemaField(
        t,
        yup.string(),
        isRequiredConfirmPAN
      ).oneOf(
        [yup.ref('panNumber'), null],
        t(
          'online-giving.my-donations.india-pan.confirm-pan-did-not-match]',
          'Please enter a matching Permanent Account Number (PAN)'
        )
      ),
    });
  const individualId = user?.individualId;
  const { t } = useTranslation();
  const [AddPan] = useAddPan();
  const { addError, addSuccess } = useNotifications();
  const [state, setState] = useState({
    isDisplay: false,
  });
  const [statePAN, setStatePAN] = useState({
    userPanInfo: '',
  });

  const { data, loading, error } = useFetchProfileDetails(individualId || '');
  useErrorHandling(
    t('view-profile.query.error', 'An error occurred.'),
    error !== undefined,
    'profile-view.error'
  );

  const netForumMemberId = data?.individual.riIndividualId || '';

  const { data: panDATA } = useGetGin(netForumMemberId.toString());

  useEffect(() => {
    if (panDATA?.results?.PAN_c) {
      setStatePAN({ userPanInfo: panDATA?.results.PAN_c.toUpperCase() });
      setState({ ...state, isDisplay: true });
    }
  }, [panDATA]);

  if (error || !individualId) {
    return <p>error</p>;
  }
  if (!data || loading) {
    return <Loading />;
  }

  const {
    prefix,
    firstName,
    middleName,
    lastName,
    suffix,
    riIndividualId,
  } = data.individual;

  const fullName = constructFullName({
    prefix,
    firstName,
    middleName,
    lastName,
    suffix,
  });

  const handleFormSubmit = async (values: FormValues) => {
    const response = await AddPan({
      variables: {
        panId: values.panNumber.toUpperCase(),
        netForumMemberId: riIndividualId?.toString() || '',
      },
    });
    if (response?.data?.addPan?.pan === 'Success') {
      addSuccess(
        t(
          'online-giving.my-donations.form.success-message',
          'Success! Your entry was saved'
        )
      );
      setState({ isDisplay: true });
      setStatePAN({ userPanInfo: values.panNumber.toUpperCase() });
    } else {
      addError(
        t(
          'online-giving.my-donations.form.error-message',
          'Error. Entry was not saved, please try again.'
        )
      );
    }
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={{
        panNumber: '',
        confirmPanNumber: '',
      }}
      validationSchema={validationSchema(t)}
    >
      {({ isSubmitting, resetForm, values }) => {
        return (
          <Form>
            <LinkPrevious
              path="/my-donations"
              label={t(
                'online-giving.my-donations.back-link.label',
                'My Donations'
              )}
            />
            <Title>
              {t(
                'online-giving.my-donations.title',
                'Government Identification Number'
              )}
            </Title>
            <p className="text-big mb-2">
              {t(
                'online-giving.my-donations.pan-govt-notification',
                'Due to government regulations in India, donors giving in Indian rupee must provide their government identification number.'
              )}
            </p>
            <h2 className="mt-18">
              {t(
                'online-giving.my-donations.manage-pan-lable',
                'Manage your Indian Permanent Account Number (PAN)'
              )}
            </h2>
            <p>
              {t(
                'online-giving.my-donations.notify-user-pan',
                'You can add your Permanent Account Number (PAN). To make changes or corrections to your Permanent Account Number (PAN) as held in your Rotary records call the SAO Finance team at 91-11-42250138.'
              )}
            </p>

            <p className="text-big mb-2">{fullName}</p>

            {state.isDisplay ? (
              <div className="w-min">
                <PasswordInput
                  name="panNumberReadonly"
                  value={statePAN.userPanInfo}
                  type="text"
                  passwordShowHide
                />
              </div>
            ) : (
              <div>
                <div className="w-1/2">
                  <PasswordInput
                    name="panNumber"
                    label={t(
                      'online-giving.my-donations.india-pan.label',
                      'Indian Permanent Account Number (PAN)'
                    )}
                    value={values.panNumber || ''}
                  />
                  <PasswordInput
                    name="confirmPanNumber"
                    label={t(
                      'online-giving.my-donations.india-pan.confirm-label',
                      'Confirm your Indian Permanent Account Number (PAN)'
                    )}
                    passwordShowHide={!isEdge && true}
                    value={values.confirmPanNumber || ''}
                  />
                </div>
                <div className="tablet:flex mt-16">
                  <Button
                    className="mb-5 tablet:mr-5 tablet:mb-0"
                    disabled={isSubmitting}
                  >
                    {t(
                      'online-giving.my-donations.form.save-button-label',
                      'Save'
                    )}
                  </Button>
                  <Button
                    type="button"
                    secondary
                    disabled={isSubmitting}
                    clickHandler={resetForm}
                  >
                    {t(
                      'online-giving.my-donations.form.cancel-button-label',
                      'Cancel'
                    )}
                  </Button>
                </div>
              </div>
            )}
            <div className="pt-48">
              <Icon
                name="squares/secure"
                color="white"
                size="20"
                className="m-auto block"
              />
              <p className="text-center m-5">
                {t(
                  'online-giving.my-donations.data-safety.india-pan',
                  'Your data is safe.'
                )}
              </p>
              <hr className="border-black" />
              <p className="mt-5">
                {t(
                  'online-giving.my-donations.privacy-policy.india-pan',
                  'Your privacy is important to Rotary. The personal data you share with Rotary will only be used for official Rotary business. This means that the personal data you provide will primarily be used for financial processing, supporting The Rotary Foundation, communicating key organizational messages and responding to your inquiries. Personal data collected on this form is subject to <a href="https://my.rotary.org/en/privacy-policy" target="_blank">Rotary’s Privacy Policy</a>.'
                )}
              </p>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
export default IndiaPanForm;
